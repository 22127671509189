import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

interface IProps {}

const About = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [values, setValues] = React.useState([
    {
      title: fm({ id: "values-1" }),
      desc: fm({ id: "values-1-desc" }),
    },
    {
      title: fm({ id: "values-2" }),
      desc: fm({ id: "values-2-desc" }),
    },
    {
      title: fm({ id: "values-3" }),
      desc: fm({ id: "values-3-desc" }),
    },
    {
      title: fm({ id: "values-4" }),
      desc: fm({ id: "values-4-desc" }),
    },
    {
      title: fm({ id: "values-5" }),
      desc: fm({ id: "values-5-desc" }),
    },
  ]);
  return (
    <>
      <SEO title={fm({ id: "menu-about" })} />
      <Layout>
        <div>
          <div className="relative z-40">
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
              <StaticImage
                src="../images/bg7.jpg"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div>
            <div className="container relative mx-auto px-4 h-screen flex items-center justify-center text-white">
              <p
                className="text-xl font-cool w-full lg:w-2/3 lg:text-3xl tracking-wider leading-snug text-white"
                data-sal="slide-up"
                data-dal-delay="1000"
                data-sal-duration={2000}
              >
                {fm({ id: "about-title" })}
              </p>
            </div>
          </div>
          <div className="min-h-screen w-full overflow-hidden font-cool flex flex-col relative justify-center bg-white">
            <div className="bg-c1 bg-opacity-50 w-screen transform translate-x-3/4 h-screen  rounded-full absolute right-0 bottom-0 top-0"></div>
            <div
              className="container p-4 relative mx-auto"
              data-sal="slide-up"
              data-dal-delay="1000"
              data-sal-duration={2000}
            >
              <div className="flex flex-col lg:flex-row items-center">
                <h1 className="w-full lg:w-4/12 text-center border-b-0 mb-5 pb-5 lg:border-b-0 lg:mb-0 lg:pb-0 border-main text-3xl lg:text-5xl">
                  {fm({ id: "about-vision" })}
                </h1>
                <div className="w-2/12 hidden lg:block text-center">
                  <div className="inline-flex">
                    <div className="border-r-4 mx-auto bg-opacity-50 border-main border-opacity-50 w-1 mr-1 h-36"></div>
                    <div className="border-r-4 mx-auto bg-opacity-50 border-main w-1 h-36"></div>
                  </div>
                </div>
                <p className="w-full lg:w-6/12 text-xl">
                  {fm({ id: "about-vision-desc" })}
                </p>
              </div>
              <div className="my-20 border-b border-black border-opacity-10"></div>
              <div className="flex flex-col lg:flex-row items-center">
                <h1 className="w-full lg:w-4/12 text-center text-3xl lg:text-5xl border-b-0 mb-5 pb-5 lg:border-b-0 lg:mb-0 lg:pb-0 border-main">
                  {fm({ id: "about-mission" })}
                </h1>
                <div className="hidden lg:block w-2/12 text-center">
                  <div className="inline-flex">
                    <div className="border-r-4 mx-auto bg-opacity-50 border-main border-opacity-50 w-1 mr-1 h-36"></div>
                    <div className="border-r-4 mx-auto bg-opacity-50 border-main w-1 h-36"></div>
                  </div>
                </div>
                <p className="w-full lg:w-6/12 text-xl">
                  {fm({ id: "about-mission-desc" })}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full overflow-hidden flex flex-col relative justify-center bg-white">
            <div className="bg-c1 bg-opacity-50 w-full lg:w-screen transform lg:-translate-x-3/4 h-screen  rounded-full absolute left-0 bottom-0 top-0"></div>
            <div className="container p-4 relative mx-auto">
              <div className="flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-1/2 mb-20 lg:mb-0">
                  <h1 className="text-3xl lg:text-5xl font-cool mb-10">
                    {fm({ id: "about-values" })}
                  </h1>
                  {/* <p className="text-main italic font-cool text-2xl">
                    {fm({ id: "about-values-desc" })}
                  </p> */}
                </div>
                <div className="w-full lg:w-1/2">
                  <div className="bg-c2 absolute h-full right-0 w-full transform lg:translate-x-1/2"></div>
                  <ul className="relative px-8 lg:px-16 py-10 lg:py-20">
                    {values?.map((v, i) => (
                      <li
                        key={`valuer-${i}`}
                        className="py-10 border-b flex space-x-10"
                      >
                        <span className="text-3xl relative -top-3 text-black text-opacity-30">
                          ....
                        </span>
                        <div>
                          <h3 className="text-xl italic font-bold text-black text-opacity-90">
                            {v.title}
                          </h3>
                          <span className="italic text-lg text-black text-opacity-50">
                            {v.desc}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default About;
